import React from 'react';
import tokens from 'tokens';

import Groups2Icon from '@mui/icons-material/Groups2';
import {
  AttachMoney,
  LocalOffer,
  QuestionAnswer,
  SupervisedUserCircleRounded,
  TrendingUp,
} from '@material-ui/icons';

import {
  Box,
  Collapse,
  List,
  ListItem,
  ListItemText,
  createStyles,
  makeStyles,
} from '@material-ui/core';
import { Link, useLocation, useRouteMatch } from 'react-router-dom';
import { useAppSelector } from 'redux/hooks';

import { KDSPageNames } from 'constants/index';
import { clsx } from 'clsx';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      padding: 0,
      margin: `0 -${tokens.spacing.md}`,
    },
    icon: {
      marginRight: tokens.spacing.sm,
    },
    listItem: {
      paddingLeft: tokens.spacing.md,
      height: 40,
      marginBottom: tokens.spacing.sm,
      color: tokens.colorGray40,
      borderLeft: `4px solid ${tokens.colorBrandprimary}`,
      '&:hover': {
        opacity: 0.9,
        color: tokens.colorBodynegative,
      },
    },
    listItemSelected: {
      borderLeft: '4px solid #F15223',
      color: tokens.colorBodynegative,
      backgroundColor: tokens.colorBrandprimary,
    },
    subNavItem: {
      color: tokens.colorGray40,
      paddingBottom: 0,
      paddingTop: 0,
      '&:hover': {
        opacity: 0.9,
        color: tokens.colorBodynegative,
      },
    },
    subNavItemSelected: {
      color: tokens.colorBodynegative,
      backgroundColor: tokens.colorBrandprimary,
    },
    verticalDivider: {
      borderRight: '1px solid rgb(232 232 232)',
      height: 40,
      width: 2,
      opacity: '25%',
      margin: '0 auto',
    },
  })
);

interface NavItemProps {
  to: string;
  children: React.ReactNode;
}

const NavigationItem = ({ to, children }: NavItemProps) => {
  const classes = useStyles();
  const match = useRouteMatch(to);

  const audienceLoading = useAppSelector(
    (state) => state.audienceReducer.ui.audienceLoading
  );

  const isAudienceLoading = audienceLoading.state === 'loading';
  const isSelected = match ? match.params : false;

  return (
    <ListItem
      button
      disabled={isAudienceLoading}
      className={clsx([
        classes.listItem,
        isSelected && classes.listItemSelected,
      ])}
      component={Link}
      to={to}
    >
      {children}
    </ListItem>
  );
};

const SubNavItem = ({ to, children }: NavItemProps) => {
  const classes = useStyles();
  const match = useRouteMatch(to);

  const isSelected = match ? match.isExact : false;

  return (
    <ListItem
      button
      className={clsx([
        classes.subNavItem,
        isSelected && classes.subNavItemSelected,
      ])}
      component={Link}
      to={to}
    >
      <Box display="flex" justifyContent="center" marginRight="26px" width={24}>
        <Box className={classes.verticalDivider}></Box>
      </Box>

      {children}
    </ListItem>
  );
};

const Navigation: React.FC = () => {
  const classes = useStyles();
  const location = useLocation();
  const { url } = useRouteMatch();

  const isOfferWallManagerOpen = location.pathname.includes(
    `${url}/offer-wall-manager`
  );

  const isQuestionManagerOpen = location.pathname.includes(
    `${url}/quick-questions-manager`
  );

  return (
    <List component="nav" className={classes.root}>
      <NavigationItem to={`${url}/audience-manager`}>
        <SupervisedUserCircleRounded className={classes.icon} />
        <ListItemText primary="Audience Manager" />
      </NavigationItem>
      <NavigationItem to={`${url}/${KDSPageNames.CUSTOMAUDIENCES}`}>
        <Groups2Icon className={classes.icon} />
        <ListItemText primary="Custom Audiences" />
      </NavigationItem>
      {/* <NavigationItem to={`${url}/sales-lift-studies`}>
        <TrendingUp className={classes.icon} />
        <ListItemText primary="Sales Lift Studies" />
      </NavigationItem> */}
      <NavigationItem to={`${url}/quick-questions-manager`}>
        <QuestionAnswer className={classes.icon} />
        <ListItemText primary="Quick Questions" />
      </NavigationItem>
      <Collapse in={isQuestionManagerOpen} timeout="auto" unmountOnExit>
        <List component="div">
          <SubNavItem to={`${url}/quick-questions-manager`}>
            <ListItemText primary="Questions" />
          </SubNavItem>
          <SubNavItem to={`${url}/quick-questions-manager/ordering`}>
            <ListItemText primary="Ordering" />
          </SubNavItem>
        </List>
      </Collapse>
      <NavigationItem to={`${url}/offer-wall-manager`}>
        <LocalOffer className={classes.icon} />
        <ListItemText primary="Offer Wall Manager" />
      </NavigationItem>
      <Collapse in={isOfferWallManagerOpen} timeout="auto" unmountOnExit>
        <List component="div">
          <SubNavItem to={`${url}/offer-wall-manager`}>
            <ListItemText primary="Offers" />
          </SubNavItem>
          <SubNavItem to={`${url}/offer-wall-manager/preview`}>
            <ListItemText primary="Placements" />
          </SubNavItem>
          <SubNavItem to={`${url}/offer-wall-manager/creative-rotations`}>
            <ListItemText primary="Creative Rotations" />
          </SubNavItem>
          <SubNavItem to={`${url}/offer-wall-manager/ab-tests`}>
            <ListItemText primary="A/B Testing" />
          </SubNavItem>
        </List>
      </Collapse>
    </List>
  );
};

export default Navigation;
