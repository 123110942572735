import Field from 'components/Field';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import _debounce from 'lodash/debounce';
import { Button } from '@klover/attain-design-system';
import {
  DocumentData,
  QuerySnapshot,
  collection,
  onSnapshot,
} from 'firebase/firestore';
import { Formik, FormikValues } from 'formik';
import { FsCollections, KDSPageNames } from 'constants/index';
import { fs } from 'utils/firebase';
import {
  useFetchCustomAudienceGroups,
  useFetchCustomAudiences,
} from './hooks/useFetchCustomAudiences';
import { useLocation } from 'react-router-dom';
import { useUser } from 'reactfire';

// Components
import BootstrapTooltip from 'components/bootstrapTooltip';
import CreateCustomAudienceDialog from './createCustomAudienceDialog';
import CreateCustomAudienceGroup from './createCustomAudienceGroup';
import CustomAudienceTable from './customAudiencesTable';
import Header from 'components/Header';

// MUI imports
import { Box, CircularProgress } from '@material-ui/core';

import GroupName from './groupName';
import * as Styled from './index.styles';
import { getAudeinceEmails } from '../../helper/helper';

export interface CustomAudienceGroup {
  id: number;
  name: string;
}

const CustomAudiences = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const [groupId, setGroupId] = useState(searchParams.get('groupId') || 'all');
  const { data: user } = useUser();
  const currentUser = user || { email: '' };
  const [selectedGroup, setSelectedGroup] =
    useState<CustomAudienceGroup | null>(null);
  const [showCreateCustomAudience, setShowCreateCustomAudience] =
    useState(false);
  const [showCreateGroup, setShowCreateGroup] = useState(false);
  const [emails, setEmails] = useState([]);
  const [oprations, setOprations] = useState({
    page: 0,
    rowsPerPage: 25,
    sortBy: 'createdat',
    sortOrder: 'DESC',
    search: '',
    groupId: searchParams.get('groupId') || 'all',
    email: searchParams.get('email') || 'all'
  });
  const [search, setSearch] = useState('');
  const [isEmailLoading, setIsEmailLoading] = useState(true);

  useEffect(() => {
    async function fetchMyAPI() {
      let response = await getAudeinceEmails(true);
      let allUsers = [];
      response.data.map((user: any) => {
        allUsers.push({ label: user.email, id: user.email })
      });
      setEmails(allUsers);
      if (!searchParams.get('email') && allUsers?.some(a => a.id === currentUser.email)) {
        setOprations({
          ...oprations,
          email: currentUser.email
        });
      }
      setIsEmailLoading(false);
    }
    fetchMyAPI();
  }, []);

  const emailOptions = useMemo(() => {
    const data = [
      { label: 'All Emails', id: 'all' },
      ...emails
    ];
    return data;
  }, [emails]);

  const formikValues: FormikValues = {};
  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const id = searchParams.get('groupId') || 'all';
    if (id && groupId !== id) {
      setGroupId(id);
    }
  }, [window.location.search]);

  const {
    isLoading: isGroupsLoading,
    groupsData: groups,
    reFetch: reFetchGroups,
  } = useFetchCustomAudienceGroups('GET', null, null);

  const {
    isLoading: isAudiencesLoading,
    responseData: customAudiences,
    reFetch: reFetchAudiences,
  } = useFetchCustomAudiences(
    'GET',
    null,
    null,
    null,
    oprations?.search,
    oprations?.rowsPerPage,
    oprations?.sortOrder,
    oprations?.sortBy,
    oprations?.page,
    oprations?.groupId,
    oprations?.email,
    isEmailLoading
  );

  // set up listener for custom audience notifications
  useEffect(() => {
    const notificationsRef = collection(fs, FsCollections.NOTIFICATIONS);
    const unsubNotifications = onSnapshot(notificationsRef, handleSnapshot);
    return () => {
      unsubNotifications();
    };
  }, []);

  useEffect(() => {
    handleSelectGroup(groupId);
  }, [groups, groupId]);

  const handleSnapshot = (snapshot: QuerySnapshot<DocumentData>) => {
    const data = snapshot.docChanges()[0]?.doc.data();
    const type = snapshot.docChanges()[0]?.type;
    if (type === 'added') {
      if (data.page === KDSPageNames.CUSTOMAUDIENCES) {
        reFetchAudiences();
      }
    }
  };
  const handleCreateAudience = () => {
    setShowCreateCustomAudience(true);
  };
  const handleCreateGroup = () => {
    setShowCreateGroup(true);
  };

  const handleSelectGroup = (id: string | null | number) => {
    if (id && id != 'all') {
      const groupselected: CustomAudienceGroup | undefined = groups?.find(
        (group: CustomAudienceGroup) => group.id == id
      );
      setSelectedGroup(groupselected ? groupselected : null);
    } else {
      setSelectedGroup(null);
    }

    setOprations({
      ...oprations,
      groupId: id,
      page: 0,
    });

    if (id !== groupId) {
      window.history.replaceState(
        null,
        '',
        oprations.email ? `${location.pathname}?groupId=${id}&email=${oprations.email}` : `${location.pathname}?groupId=${id}`
      );
    }
  };

  const debounceFn = useCallback(
    _debounce((value) => {
      setOprations({
        ...oprations,
        page: 0,
        search: value,
      });
    }, 1000),
    [oprations]
  );

  const groupsOptions = useMemo(() => {
    if (groups) {
      const data = [
        { label: 'All Audiences', id: 'all' },
        ...groups
          ?.sort((a, b) => b.updated_at - a.updated_at)
          ?.map((a) => {
            return { label: a.name, id: `${a.id}` };
          }),
      ];
      return data;
    }
    return [];
  }, [groups]);

  return (
    <Box>
      <Header
        title="Custom Audiences"
        centerBorder={true}
        center={
          selectedGroup ? (
            <GroupName
              group={selectedGroup}
              reFetchGroups={reFetchGroups}
              handleSelectGroup={handleSelectGroup}
            />
          ) : null
        }
        action={
          <BootstrapTooltip
            title="Select a group to create an audience"
            disableHoverListener={!!selectedGroup}
          >
            <div>
              <Button
                variant="contained"
                onClick={handleCreateAudience}
                disabled={!selectedGroup}
                style={{ fontSize: 14 }}
              >
                Create Custom Audience
              </Button>
            </div>
          </BootstrapTooltip>
        }
      />
      <Box height="100%" padding={2}>
        <Styled.SearchArea>
          {isGroupsLoading || isEmailLoading ? (
            <CircularProgress color="primary" size={20} />
          ) : (
            <>
              <Styled.SelectAutoCompleteGroup
                disablePortal
                id="select-audience-group"
                options={groupsOptions}
                value={
                  groupsOptions.find((a) => `${a?.id}` === `${groupId}`)?.label
                }
                renderInput={(params) => (
                  <Styled.SelectAutoCompleteGroupInput
                    {...params}
                    placeholder="Select Audience Group"
                  />
                )}
                onChange={(e, newValue) => {
                  if (newValue?.id) {
                    handleSelectGroup(newValue.id);
                  }
                }}
                renderOption={(props, option) => (
                  <Styled.SelectAutoCompleteGroupOptions>
                    <li {...props} key={option.id}>
                      {option.label}
                    </li>
                  </Styled.SelectAutoCompleteGroupOptions>
                )}
              />
              <Styled.Or>Or</Styled.Or>
              <Button
                variant="contained"
                color="primary"
                onClick={handleCreateGroup}
                style={{ fontSize: '14px' }}
              >
                Create Group
              </Button>

              <Formik
                initialValues={formikValues}
                onSubmit={() => {
                  console.log('form submitted');
                }}
              >
                <Styled.Form onSubmit={(e) => e.preventDefault()}>
                  <Field
                    name="searchCustomAudiences"
                    placeholder="Search Audiences"
                    value={search}
                    onChange={(e: any) => {
                      setSearch(e.target.value);
                      debounceFn(e.target.value);
                    }}
                  />
                </Styled.Form>
              </Formik>
              <Styled.SelectAutoCompleteEmail
                disablePortal
                id="select-audience-email"
                options={emailOptions}
                value={
                  emailOptions.find((a) => a?.id === `${oprations.email}`)
                    ?.label
                }
                renderInput={(params) => (
                  <Styled.SelectAutoCompleteGroupInput
                    {...params}
                    placeholder="Select User Email"
                  />
                )}
                onChange={(e, newValue: any) => {
                  if (newValue?.id) {
                    setOprations({
                      ...oprations,
                      page: 0,
                      email: newValue?.id,
                    });
                    if (newValue?.id !== oprations.email) {
                      window.history.replaceState(
                        null,
                        '',
                        groupId ? `${location.pathname}?groupId=${groupId}&email=${newValue?.id}` : `${location.pathname}?email=${newValue?.id}`
                      );
                    }
                  } else {
                    setOprations({
                      ...oprations,
                      page: 0,
                    });
                  }                  
                }}
                renderOption={(props, option) => (
                  <Styled.SelectAutoCompleteGroupOptions>
                    <li {...props} key={option.id}>
                      {option.label}
                    </li>
                  </Styled.SelectAutoCompleteGroupOptions>
                )}
              />
            </>
          )}
        </Styled.SearchArea>
      </Box>
      {isAudiencesLoading ? (
        <Stack
          direction="row"
          justifyContent="center"
          alignItems="center"
          sx={{ width: 1, height: '80vh' }}
        >
          <CircularProgress color="primary" size={40} />
        </Stack>
      ) : (
        <Box padding={2}>
          <CustomAudienceTable
            customAudiences={customAudiences}
            groups={groups}
            selectedGroupId={groupId}
            handleSelectGroup={handleSelectGroup}
            oprations={oprations}
            setOprations={setOprations}
          />
        </Box>
      )}
      <CreateCustomAudienceDialog
        show={showCreateCustomAudience}
        setShow={setShowCreateCustomAudience}
        selectedGroup={selectedGroup}
        reFetchAudiences={reFetchAudiences}
      />
      <CreateCustomAudienceGroup
        show={showCreateGroup}
        setShow={setShowCreateGroup}
        setSelectedGroup={setSelectedGroup}
        reFetchGroups={reFetchGroups}
      />
    </Box>
  );
};

export default CustomAudiences;
