import { Pagination } from 'interfaces/notifications';

export enum UploadStates {
  DONE = 'DONE',
  LOADING = 'LOADING',
  ERROR = 'ERROR',
}
export enum NotificationStates {
  DONE = 'DONE',
  ERROR = 'ERROR',
}

export enum FsCollections {
  NOTIFICATIONS = 'notifications',
  TOASTERS = 'toasters',
}

export enum LoadingStates {
  IDLE = 'idle',
  LOADING = 'loading',
  DONE = 'done',
  FAILED = 'failed',
  ERROR = 'error',
}

export enum KDSPageNames {
  AUDIENCEMANAGER = 'audience-manager',
  OFFERWALLMANAGER = 'offer-wall-manager',
  SALESLIFT = 'sales-lift-studies',
  QUICKQUESTIONS = 'quick-questions-manager',
  NOTIFICATIONS = 'notifications',
  CUSTOMAUDIENCES = 'custom-audience-manager',
}

export enum ValidationErrorMessages {
  PAYOUT = 'Payout value must be zero or a positive number',
  POINTS = 'Klover Points value must be zero or a positive number',
  CONVERSION_CAPPING = 'Conversion Capping value must be zero or a positive number',
  TUNE_GOALS_POINTS_AWARDED = 'Klover Points Award for Tune Goal must be zero or a positive number',
  TUNE_GOALS_ID = 'Tune Goal ID for Tune Goal must be zero or a positive number',
  TUNE_GOALS_ID_REQUIRED = 'Tune Goal ID for Tune Goal is required',
  TUNE_GOALS_CONVERSION_VALUE = 'Cap Limit for Tune Goal must be zero or a positive number',
  NON_NEGATIVE_NUMBER = 'Value must be zero or a positive number',
  FUTURE_START_DATE_ACTIVE_OFFER = 'Future start date not allowed for active offer',
  START_DATE_SHOULD_BE_IN_FUTURE = 'Date should be in future for inactive offer',
  END_DATE_EARLIER_THAN_START_DATE = 'End Date should not be before start date',
  CURRENT_DATE_NOT_IN_RANGE = 'Ensure current date falls within flight date range',
}

export const DefaultPaginationValues: Pagination = { page: 1, limit: 15 };

export enum ExportAudience {
  KLOVER_IDS = 'Klover IDs',
  KLOVER_RAMP_IDS = 'Klover + Ramp IDs',
  TRANSUNION = 'TransUnion',
  TRANSUNION_TTD = 'TransUnion + TTD',  
  TRANSUNION_HEMS = 'TransUnion HEMs Only',
  CUSTOM_SEGMENT = 'Custom Segment',
  OTS_SEGMENT = 'OTS Segment'
}

export const CUSTOM_SEGMENT_INFORMATION = [
  "KDS audience will include email, phone, first, last name, and address",
  "Sends this audience to TransUnion for lookalike modeling",
  "You need to login to TU to adjust modeling settings",
  "Once TransUnion is finished building the lookalike model, a new custom segment will be created in The Trade Desk."
];

export const OTS_SEGMENT_INFORMATION = [
  "KDS audience will include HEMs only",
  "Sends this audience to TransUnion for lookalike modeling",
  "TransUnion will automatically create lookalike models and send them to LiveRamp and to our platform",
  "Our platform will create a new OTS segment in The Trade Desk."
];

export const EXPORT_EMAIL_INFO_TEXT = "Supply an email address to send a CSV file with the audience information you created.";
export const SELECT_EXPORT_TYPE = "Select export type";
export const REQUIRED_FIELDS_ARE_MISSING = "Required fields are missing";
export enum TRADE_DESK_SEGMENT_FIELD_NAME {
  ADVERTISER_ID = "Advertiser ID",
  PARTNER_ID = "Partner ID",
  PERCENT_OF_MEDIA = "% of media",
  CPM = "CPM",
  DESCRIPTION = "Description",
  CUSTOM_SEGMENT_NAME = "Custom Segment Name",
  OTS_SEGMENT_NAME = "OTS Segment Name"
}
export const TRADE_DESK_SEGMENT_DETAILS = 'The Trade Desk Segment Details';
export const AUDIENCE_STATUS_HEADER_TITLE = 'Status';
export const AUDIENCE_SELECT_EMAIL_AUTO_COMPLETE = 'Select email';
export const EXPORT_STATUS_HEADER_TITLE = 'What does this mean?';
export const TABLE_SORT_TOOLTIP_TEXT = 'Toggle SortBy';
